<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-lg p-6 space-y-4 overflow-hidden text-left text-white align-middle transition-all transform shadow-xl bg-brand-orange md:space-y-8 md:p-8 rounded-2xl"
            >
              <DialogTitle as="h3" class="text-2xl font-bold leading-7"
                >Video and Photography Consent</DialogTitle
              >
              <div
                class="space-y-2 md:space-y-3 overflow-y-scroll max-h-[50vh] md:max-h-[20vh] bg-white px-4 py-3 rounded-lg -mx-3"
              >
                <h4 class="font-semibold">Privacy Consent</h4>
                <p>
                  This form should be signed by persons appearing in photographs
                  or video footage used by VicHealth. The form includes a
                  privacy consent to use the person's images.
                </p>
                <p>
                  Thank you for providing your photograph(s) and/or video
                  footage (the materials) to the Victorian Health Promotion
                  Foundation (VicHealth). VicHealth would like your permission
                  to use the materials in the future for any educational and/or
                  promotional purpose that is consistent with VicHealth's
                  responsibilities. Please read this form carefully before
                  signing.
                </p>
                <p>
                  I give permission to VicHealth, authorised media outlets,
                  approved partner organisations and government partners to use
                  the video footage and/or photographs for any educational
                  and/or promotional purpose that is consistent with VicHealth's
                  responsibilities. I understand that the video footage and/or
                  photographs may be used in a range of online and printed
                  formats, including but not limited to printed publications,
                  television or print advertising, online publications and on
                  websites, YouTube, Facebook, Twitter, blogs, microblogs and
                  any other media (including social media).
                </p>
                <p>
                  I understand that VicHealth cannot control or be held
                  responsible for any adverse or defamatory use of this video
                  footage and/or these photographs by third parties, once they
                  have been published online or in printed format.
                </p>
                <p>
                  I understand that I will not receive any compensation or
                  remuneration from VicHealth arising out of the use of the
                  video footage and/or photographs.
                </p>
                <p>
                  I understand that VicHealth will comply with the Privacy and
                  Data Protection Act, 2014 (Vic) in connection with my personal
                  information.
                </p>
                <h4 class="font-semibold pt-6">Withdrawal of Consent</h4>
                <p>
                  Please note that you are entitled at any time to withdraw your
                  consent to the above uses by contacting the Privacy Officer by
                  telephone on 03 9667 1333, email at
                  privacy@vichealth.vic.gov.au or in writing to VicHealth,
                  Melbourne Office, PO Box 13137, Law Courts, Victoria 8010.
                </p>
                <p>
                  If you do withdraw your consent, VicHealth will discontinue
                  any further use of the image/s and/or video footage and will
                  use its best endeavours to remove such image and/or video
                  footage from its online publications.
                </p>
                <p>
                  You acknowledge that VicHealth's ability to remove your image
                  and/or video footage from the internet is limited where such
                  materials have been published by third parties.
                </p>
                <h4 class="font-semibold pt-6">Authorisation</h4>
                <p>
                  I represent and warrant that I am at least 18 years of age and
                  have read and understood this consent form. I agree to the
                  terms and conditions above.
                </p>
                <p>
                  Where the person is under 18 or subject to guardianship order,
                  by their parent or legal guardian, the second confirmation
                  field should be checked.
                </p>
              </div>

              <div class="md:text-center flex flex-col gap-3">
                <p class="font-semibold">
                  But clicking below you AGREE TO THE ABOVE and PROVIDE CONSENT
                  for your photo and/or video to be used by VicHealth.
                </p>
                <IconButton
                  type="button"
                  ref="confirmButton"
                  title="Sign & Submit"
                  size="small"
                  @click.native="confirm"
                />
                <IconButton
                  type="button"
                  ref="closeButton"
                  title="Close"
                  size="small"
                  @click.native="closeModal"
                  class="opacity-75 hover:opacity-100"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import IconButton from "@/Components/IconButton";

export default {
  emits: ["callback"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    IconButton,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("callback", {
        action: "close",
      });
    },
    confirm() {
      this.isOpen = false;
      this.$emit("callback", {
        action: "confirm",
      });
    },
  },
};
</script>
