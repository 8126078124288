<template>
  <label
    class="flex items-start space-x-6 text-sm font-bold cursor-pointer lg:text-lg"
  >
    <input
      type="radio"
      name="age"
      class="w-6 h-6 rounded cursor-pointer focus:ring-brand-blue-400 text-brand-blue-400"
      :value="value"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <div class="flex">
      <span>
        <slot />
        <div
          v-if="error"
          class="inline-block px-2 text-xs font-bold rounded-full shadow-sm text-white bg-brand-blue-400 lg:text-base"
        >
          {{ error }}
        </div>
      </span>
    </div>
  </label>
</template>

<script>
export default {
  emit: ["update:modelValue"],
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
