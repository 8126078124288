import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTiktok,
  faTwitter,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faUpFromLine,
  faRotateLeft,
  faCameraWeb,
  faLoader,
  faStop,
  faPause,
  faPlay,
  faRepeat,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faCheck,
  faVideo,
  faEnvelope,
  faFaceSmile,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faUpFromLine,
  faRotateLeft,
  faCameraWeb,
  faLoader,
  faStop,
  faCheck,
  faPause,
  faPlay,
  faRepeat,
  faVideo,
  faArrowLeft,
  faTiktok,
  faTwitter,
  faFacebookF,
  faInstagram,
  faEnvelope,
  faFaceSmile
);

export default FontAwesomeIcon;
