<template>
  <div class="w-10/12 font-black text-center">
    <div class="text-center prompt text-shadow" v-show="step === 'prompt'">
      <div class="flex flex-col text-6xl">
        <span>GET</span>
        <span>READY!</span>
      </div>
      <span class="font-bold">Min: 5 sec - Max: 20 sec</span>
    </div>
    <div
      class="relative countdown text-9xl text-shadow"
      v-show="step === 'countdown'"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  data() {
    return {
      step: "prompt",
      text: 3,
    };
  },
  mounted() {
    const tl = anime.timeline({
      easing: "easeOutElastic(1, .6)",
    });

    tl.add({
      targets: ".prompt",
      opacity: [0, 1],
      scale: [4, 1],
      duration: 3000,
      complete: (anim) => {
        this.step = "countdown";
        this.text = 3;
      },
    })
      .add({
        targets: ".countdown",
        opacity: [0, 1],
        scale: [5, 1],
        duration: 1000,
        complete: (anim) => {
          this.text = 2;
        },
      })
      .add({
        targets: ".countdown",
        opacity: [0, 1],
        scale: [5, 1],
        duration: 1000,
        complete: (anim) => {
          this.text = 1;
        },
      })
      .add({
        targets: ".countdown",
        opacity: [0, 1],
        scale: [5, 1],
        duration: 1000,
        complete: (anim) => {
          this.$emit("completed");
        },
      });
  },
};
</script>
