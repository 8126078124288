<template>
  <div>
    <div
      id="player"
      ref="player"
      class="relative overflow-hidden text-white bg-black aspect-w-3 aspect-h-4"
    >
      <div class="absolute inset-0 w-full h-full">
        <div
          class="absolute inset-0 z-30 flex items-center justify-center text-white bg-black"
          v-if="ui.permissionRequest"
        >
          <div
            class="relative flex flex-col items-center justify-center w-10/12 space-y-5 text-center md:w-2/3"
          >
            <FontAwesome
              :icon="['fas', 'camera-web']"
              class="text-4xl text-brand-blue-400 md:text-6xl"
            />
            <h3 class="text-lg font-bold md:text-2xl">
              Camera & Microphone Access
            </h3>
            <p class="text-sm md:text-lg">
              In order to record a video we'll need access your camera &
              microphone. Tap the button below and then grant permission in your
              device's browser.
            </p>
            <ActionButton
              title="Request Permission"
              @click.native="initCameras"
            />
          </div>
        </div>
        <div
          class="absolute inset-0 z-30 flex items-center justify-center text-white"
          v-if="ui.countdownAnimation"
        >
          <CountdownAnimation @completed="toggleRecording" />
        </div>
        <!-- <div v-if="!loading && display === 'recorder'">
          <ToggleDevice v-if="canToggleDevices" @click.native="toggleCamera" />
          <ConfigButton v-else />
        </div> -->
        <LoadingAnimation v-if="loading" />
        <div
          class="absolute inset-0 z-20 flex items-center justify-center"
          v-if="display === 'player' && ui.playButton"
        >
          <button
            class="flex items-center justify-center w-20 h-20 rounded-xl md:w-32 md:h-32 bg-black/30"
            @click="$refs.previewVideo.play()"
          >
            <FontAwesome
              :icon="['fas', 'play']"
              class="relative text-5xl shadow-sm left-1"
            />
          </button>
        </div>
        <div
          class="absolute bottom-0 left-0 z-30 grid items-end w-full grid-cols-3 mb-4 sm:h-24 md:h-32 md:mb-8"
          ref="controls"
        >
          <div class="flex justify-start col-span-1 px-4 md:px-8">
            <ActionButton
              title="Retry"
              icon="rotate-left"
              v-if="ui.actionButtons"
              @click.native="retryRecording"
            />
          </div>
          <div class="flex justify-center col-span-1">
            <RecordButton
              ref="record"
              v-if="ui.recordButton"
              :icon="ui.recordButtonIcon"
              :playerWidth="playerConfig.width"
              :duration="duration"
              :config="playerConfig"
              @click.native="startRecording"
            />
          </div>
          <div class="flex justify-end col-span-1 px-4 md:px-8">
            <ActionButton
              title="Upload"
              icon="up-from-line"
              v-if="ui.actionButtons"
              @click.native="notifyFinished"
            />
          </div>
        </div>
      </div>
      <video
        ref="video"
        playsinline
        autoplay
        muted
        class="absolute inset-0 z-10 object-contain"
        v-show="display === 'recorder'"
      />
      <video
        ref="previewVideo"
        class="absolute inset-0 z-0 object-contain"
        v-show="display === 'player'"
        crossorigin
        playsinline
        preload
        autoplay
        loop
        @click="$refs.previewVideo.pause()"
      />
    </div>
    <div
      v-if="mq.isLandscape && mq.mdMinus"
      class="fixed inset-0 z-30 flex items-center justify-center w-screen h-screen bg-black pointer-events-none"
    >
      Please rotate your device to portrait mode.
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import RecordButton from "@/Components/capture/RecordButton";
import ConfigButton from "@/Components/capture/ConfigButton";
import ToggleDevice from "@/Components/capture/ToggleDevice";
import LoadingAnimation from "@/Components/capture/LoadingAnimation";
import ActionButton from "@/Components/capture/ActionButton";
import CountdownAnimation from "@/Components/capture/CountdownAnimation";

export default {
  inject: ["mq"],
  emits: ["error", "video", "finished"],
  components: {
    RecordButton,
    ConfigButton,
    ActionButton,
    ToggleDevice,
    LoadingAnimation,
    CountdownAnimation,
  },
  data() {
    return {
      display: "recorder",
      blob: null,
      mime: null,
      loading: false,
      recording: false,
      initialised: false,
      compatible: true,
      recorder: null,
      timer: 0,
      duration: 0,
      animation: null,
      devices: {
        video: [],
        audio: [],
        currentVideo: null,
        currentAudio: null,
      },
      ui: {
        recordButton: false,
        stopButton: false,
        actionButtons: false,
        permissionRequest: true,
        countdownAnimation: false,
        playButton: true,
        recordButtonIcon: {
          type: "",
          spin: false,
          active: false,
        },
      },
      resolution: {
        width: 1920,
        height: 1080,
      },
      playerConfig: {
        width: 0,
        height: 0,
        recordLength: {
          min: 5,
          max: 20,
        },
      },
      recordingConfig: {
        crossOrigin: "anonymous",
        width: 500,
        height: 500,
        x: 0,
        y: 0,
      },
      previewConfig: {
        crossOrigin: "anonymous",
        width: 1920,
        height: 1080,
        x: 0,
        y: 0,
      },
      debug: null,
    };
  },
  methods: {
    toggleConfig() {
      console.log("Clicked config button");
    },
    initCameras() {
      this.loading = true;
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport();
      }
      this.testVideoAccess();
    },
    legacyGetUserMediaSupport() {
      return (constraints) => {
        let getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia;
        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject();
        }
        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    },
    testVideoAccess() {
      let constraints = { video: true, audio: { echoCancellation: true } };
      if (this.resolution) {
        constraints.video = {};
        constraints.video.height = this.resolution.height;
        constraints.video.width = this.resolution.width;
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          let tracks = stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.loadCameras();
        })
        .catch((error) => {
          this.$emit("error", {
            title: "No recording devices detected",
            message:
              "Your browser does not support recording from this device. Please try another browser or device.",
          });
        });
    },
    loadCameras() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          for (let i = 0; i !== deviceInfos.length; ++i) {
            let deviceInfo = deviceInfos[i];
            if (deviceInfo.kind === "videoinput") {
              // store only the data we need
              this.devices.video.push({
                label: deviceInfo.label,
                id: deviceInfo.deviceId,
              });
            }
            if (deviceInfo.kind === "audioinput") {
              // store only the data we need
              this.devices.audio.push({
                label: deviceInfo.label,
                id: deviceInfo.deviceId,
              });
            }
          }
          // Load the first video source in the list
          if (this.devices.video.length > 0) {
            this.loadCamera(this.devices.video[0].id);
          }
          // Show the preview
          this.ui.permissionRequest = false;
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$emit("error", {
            title: "Cannot access camera/microphone",
            message:
              "Your webcam or microphone cannot be accessed, or permissions have not been granted successfully. Please check and try again.",
          });
        });
    },
    loadCamera(device) {
      let constraints = {
        video: {
          deviceId: { exact: device },
        },
        audio: { echoCancellation: true },
      };
      this.cameraInfo = constraints;
      if (this.resolution) {
        constraints.video.height = this.resolution.height;
        constraints.video.width = this.resolution.width;
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => this.loadSrcStream(stream))
        .then(() => {
          this.devices.currentVideo = device;
        })
        .catch((error) => {
          console.error("Error loading camera: ", error);
        });
    },
    toggleCamera() {
      const camera = this.devices.video.filter((camera) => {
        return camera.id !== this.devices.currentVideo;
      });
      this.loadCamera(camera[0].id);
    },
    loadSrcStream(stream) {
      this.$refs.video.srcObject = stream;
      this.ui.recordButton = true;

      // Load video onto canvas
      this.$refs.video.onloadedmetadata = (event) => {
        this.$refs.video.play();
        this.loading = false;
      };
    },
    startRecording() {
      if (!this.recording) {
        this.ui.countdownAnimation = true;
        this.ui.recordButtonIcon.type = "loader";
        this.ui.recordButtonIcon.spin = true;
      } else {
        this.toggleRecording();
      }
    },
    retryRecording() {
      this.display = "recorder";
      this.previewSrc = null;
      this.$refs.previewVideo.src = null;
      const stream = this.$refs.video.srcObject;
      this.loadSrcStream(stream);
      this.ui.recordButton = true;
      this.ui.actionButtons = false;
      this.ui.countdownAnimation = true;
      this.ui.recordButtonIcon.type = "loader";
      this.ui.recordButtonIcon.spin = true;
      this.ui.recordButtonIcon.active = false;
      this.duration = 0;
    },
    async toggleRecording() {
      this.ui.countdownAnimation = false;
      this.ui.actionButtons = false;
      this.display = "recorder";
      if (!this.recording) {
        const stream = this.$refs.video.srcObject;
        this.loadSrcStream(stream);

        const videoTypes = ["webm", "ogg", "mp4", "x-matroska"];
        const audioTypes = ["webm", "ogg", "mp3", "x-matroska"];
        const codecs = [
          "vp9",
          "vp9.0",
          "vp8",
          "vp8.0",
          "avc1",
          "av1",
          "h265",
          "h.265",
          "h264",
          "h.264",
          "opus",
          "pcm",
          "aac",
          "mpeg",
          "mp4a",
        ];

        const supportedVideos = this.getSupportedMimeTypes(
          "video",
          videoTypes,
          codecs
        );
        const supportedAudios = this.getSupportedMimeTypes(
          "audio",
          audioTypes,
          codecs
        );

        this.mime = supportedVideos[0];

        const recorder = new MediaRecorder(stream, {
          audioBitsPerSecond: 128000,
          mimeType: supportedVideos[0],
        });
        this.recorder = recorder;
        this.recording = true;
        this.ui.recordButtonIcon.type = "stop";
        this.ui.recordButtonIcon.spin = false;
        this.recorder.ondataavailable = async (event) => {
          await this.pushVideoData(event.data);
        };
        this.recorder.onstop = async (event) => {
          await this.loadRecording();
        };
        this.recorder.start();
        this.startTimer();
      } else {
        if (this.duration >= this.playerConfig.recordLength.min) {
          this.recorder.stop();
          this.recording = false;
          this.ui.actionButtons = true;
          this.ui.recordButton = false;
          this.display = "player";
          clearInterval(this.timer);
        }
      }
    },
    getSupportedMimeTypes(media, types, codecs) {
      const isSupported = MediaRecorder.isTypeSupported;
      const supported = [];
      types.forEach((type) => {
        const mimeType = `${media}/${type}`;
        codecs.forEach((codec) =>
          [
            `${mimeType};codecs=${codec}`,
            `${mimeType};codecs:${codec}`,
            `${mimeType};codecs=${codec.toUpperCase()}`,
            `${mimeType};codecs:${codec.toUpperCase()}`,
          ].forEach((variation) => {
            if (isSupported(variation)) supported.push(variation);
          })
        );
        if (isSupported(mimeType)) supported.push(mimeType);
      });
      return supported;
    },
    async pushVideoData(data) {
      if (data.size > 0) {
        const uid = await uuidv4();
        data.name = "clip-" + uid + ".mp4";
        this.blob = data;
      }
    },
    async loadRecording() {
      // Autoplay
      this.$refs.previewVideo.onloadeddata = (event) => {
        this.$refs.previewVideo.play();
      };

      this.$refs.previewVideo.onplay = (event) => {
        this.ui.playButton = false;
      };

      this.$refs.previewVideo.onpause = (event) => {
        this.ui.playButton = true;
      };

      this.$refs.previewVideo.src = window.URL.createObjectURL(this.blob);
    },
    startTimer() {
      this.duration = 0;
      this.timer = setInterval(() => {
        this.duration += 1;
        // Activate the recording icon
        if (this.duration >= this.playerConfig.recordLength.min) {
          this.ui.recordButtonIcon.active = true;
        }
        // Stop recording after 20 seconds
        if (this.duration >= this.playerConfig.recordLength.max) {
          this.toggleRecording();
        }
      }, 1000);
    },
    notifyFinished() {
      this.$emit("finished", {
        blob: this.blob,
        duration: this.duration,
        mime: this.mime,
      });
    },
    pauseVideo() {
      this.$refs.previewVideo.pause();
    },
  },
  computed: {
    canToggleDevices() {
      return this.devices.video.length === 2;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
#player {
  transform: translate3d(0, 0, 0);
}

video {
  object-fit: cover;
}
</style>
