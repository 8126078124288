<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-lg p-6 space-y-4 overflow-hidden text-center text-brand-green align-middle transition-all transform shadow-xl bg-brand-orange md:space-y-8 md:p-8 rounded-2xl text-white font-semibold"
            >
              <DialogTitle as="h3" class="text-2xl font-bold leading-7"
                >Share your video on {{ platformName }}</DialogTitle
              >

              <p>
                Enter your email address below and we'll send your video as an
                attachment. Save the file to your device and then share using
                the
                {{ platformName }} app.
              </p>

              <p>No email arriving? Check your spam folder just in case.</p>

              <FieldInput
                placeholder="Email address"
                v-model="form.email"
                :error="form.errors.email"
                :disabled="form.processing"
                size="small"
              />

              <div class="md:text-center">
                <IconButton
                  class="w-full border-white lg:w-auto"
                  :title="button.text"
                  :icon="['far', button.icon]"
                  v-if="!form.progress"
                  :disabled="form.processing"
                  @click.native="submit"
                  size="small"
                />
                <IconButton
                  class="w-full border-white lg:w-auto"
                  title="Requesting"
                  :icon="['fas', 'loader']"
                  :disabled="form.processing"
                  size="small"
                  icon-spin
                  v-else
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { useForm } from "@inertiajs/inertia-vue3";
import IconButton from "@/Components/IconButton";
import FieldInput from "@/Components/form/FieldInput";

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  emits: ["callback"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    IconButton,
    FieldInput,
  },
  data() {
    return {
      isOpen: false,
      button: {
        text: "Request",
        icon: "envelope",
      },
      platform: "",
      form: useForm({
        video_id: this.video.hash,
        platform: "",
        email: "",
      }),
    };
  },
  computed: {
    platformName() {
      const platforms = {
        instagram: "Instagram",
        tiktok: "TikTok",
      };
      return platforms[this.platform];
    },
  },
  methods: {
    async submit() {
      try {
        // Submit video
        this.form.post("/video/download", {
          preserveScroll: true,
          onSuccess: () => {
            // Log action in google analytics
            if (process.env.MIX_APP_ENV === "production") {
              gtag("event", `download_${this.form.platform}`, {
                event_category: "engagement",
                event_label: "method",
              });
            }

            // Clear the form
            this.form.reset();

            this.button.text = "Request sent!";
            this.button.icon = "face-smile";
            setTimeout(() => {
              this.closeModal();
            }, 1000);
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    openModal(platform) {
      // Reset button icons
      this.button = {
        text: "Request",
        icon: "envelope",
      };
      this.platform = platform;
      this.form.platform = platform;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("callback", {
        action: "close",
      });
    },
  },
};
</script>
