<template>
  <div class="relative">
    <select
      class="w-full h-12 px-4 text-lg font-bold border-2 border-white rounded-full bg-brand-blue-500 lg:h-16 lg:px-6 lg:text-2xl bg-blend-color-dodge focus:ring-0 focus:border-brand-blue-400"
      :value="modelValue"
      @change="handleChange"
    >
      <option value="" disabled>Select</option>
      <option
        :value="option.value"
        v-for="option in options"
        :ref="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <div
      v-if="error"
      class="absolute right-0 px-2 text-sm font-bold text-white rounded-full shadow-sm -top-3 bg-brand-blue-400 lg:text-base"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  emit: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style></style>
