<template>
  <Head title="Display" />
  <div
    class="z-10 h-screen text-white bg-fixed bg-cover w-screen bg-pattern overflow-hidden bg-brand-orange"
  >
    <div class="p-6 pb-0 lg:p-8">
      <div class="grid grid-cols-12 lg:gap-x-8">
        <div
          class="relative flex-col items-start col-span-12 space-y-8 lg:flex lg:justify-between lg:col-span-3"
        >
          <div
            class="sticky space-y-5 top-6 lg:top-8 lg:space-y-4 bg-brand-orange"
          >
            <div class="px-6 py-8 -ml-6 space-y-6 lg:px-12 lg:-ml-12">
              <h1 class="text-6xl font-bold text-white">
                Cheer cheer the red and white!
              </h1>
              <div
                class="space-y-4 text-[22px] text-brand-green font-semibold leading-9 tracking-tight"
              >
                <p>
                  As proud supporters of the Sydney Swans, Origin is excited to
                  cheer them on during their finals journey!
                </p>
                <p>
                  And we would love to hear your support too! Record a short
                  video with a message of encouragement to boost the Swans'
                  motivation. Together with Origin, let’s cheer the Swans to
                  victory!
                </p>
              </div>
              <div class="bg-brand-orange pt-4">
                <img
                  src="/images/logogrid.svg"
                  alt="Origin & Sydney Swans logo"
                  class="h-24"
                />
              </div>
            </div>
          </div>
          <div class="relative z-50 mt-8 space-y-8 lg:fixed lg:bottom-8">
            <div
              class="flex flex-col items-center w-[220px] p-3 pb-1 space-y-1 border-2 border-white bg-brand-blue-500 rounded-xl shrink bg-brand-orange"
              @click="refreshAndShuffle"
            >
              <img src="/images/qrcode.png" class="w-full mb-2" />
              <div
                class="flex items-center space-x-3 text-xl text-brand-green whitespace-nowrap"
              >
                <FontAwesome :icon="['far', 'video']" />
                <div class="font-semibold text-xl">Record a video</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-9">
          <TransitionGroup
            name="list"
            tag="div"
            class="grid grid-cols-2 gap-4 py-12 md:grid-cols-9 lg:py-0 lg:gap-3 xl:gap-y-4"
          >
            <GalleryThumbnail
              v-for="item in gallery"
              :item="item"
              :key="item.uuid"
              @play="playVideo"
            />
          </TransitionGroup>
        </div>
      </div>
    </div>
    <div
      v-if="videos.length"
      id="modal"
      class="fixed inset-0 top-0 z-40 flex items-center justify-center w-screen h-screen opacity-0 bg-black/75"
    >
      <div id="video" class="w-[700px] h-[933]">
        <div class="p-6 bg-white rounded-2xl">
          <vue-plyr :options="options" ref="plyr">
            <video
              crossorigin
              playsinline
              :data-poster="modal.video.poster_url"
            >
              <source
                size="1080"
                :src="modal.video.video_url_web"
                type="video/mp4"
              />
              <track
                default
                kind="captions"
                label="English"
                :src="modal.video.subtitles_url"
                srclang="en"
                v-if="modal.video.subtitles_url"
              />
            </video>
          </vue-plyr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import { v4 as uuidv4 } from "uuid";
import GalleryThumbnail from "@/Components/GalleryThumbnail";
import IconButton from "@/Components/IconButton";
import TermsLink from "@/Components/TermsLink";
import ModalWelcome from "@/Components/modals/ModalWelcome";
import ModalVideoPlayer from "@/Components/modals/ModalVideoPlayer";
import animejs from "animejs";

export default defineComponent({
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  components: {
    Head,
    Link,
    IconButton,
    TermsLink,
    GalleryThumbnail,
    ModalWelcome,
    ModalVideoPlayer,
  },
  data() {
    return {
      gallery: [],
      modal: {
        show: false,
        video: this.videos[0],
      },
      options: {
        ratio: "3:4",
        captions: {
          active: true,
          language: "en",
        },
      },
    };
  },
  methods: {
    // Conditionally open the modal
    openModal() {},
    // Generate padded gallery
    populateGallery(videos) {
      const items = videos;
      const target = 45;
      const shortfall = target - items.length;

      if (shortfall) {
        for (let i = 0; i < shortfall; i++) {
          items.push({
            uuid: uuidv4(),
            type: "placeholder",
          });
        }
        this.gallery = items;
      } else {
        this.gallery = items;
      }

      // Play a random video
      setTimeout(() => {
        this.playRandomVideo();
      }, 5000);
    },
    playVideo(video) {
      this.$refs.plyr.player.source = {
        type: "video",
        sources: [
          {
            src: video.video_url_web,
            type: "video/mp4",
            size: 1080,
          },
        ],
        poster: video.poster_url,
        tracks: [
          {
            kind: "captions",
            label: "English",
            srclang: "en",
            src: video.subtitles_url,
            default: true,
          },
        ],
      };
      this.animateOpen();
      this.$refs.plyr.player.play();
    },
    animateOpen() {
      animejs({
        targets: "#modal",
        opacity: [0, 1],
        duration: 1000,
        easing: "easeInOutQuad",
      });
      animejs({
        targets: "#video",
        opacity: [0, 1],
        scale: [0, 1],
        duration: 1000,
        easing: "easeOutElastic(1, .6)",
      });
    },
    animateClosed() {
      animejs({
        targets: "#modal",
        opacity: [1, 0],
        duration: 1000,
        easing: "easeInOutQuad",
      });
      animejs({
        targets: "#video",
        opacity: [1, 0],
        scale: [1, 0],
        duration: 1000,
        easing: "easeOutElastic(1, .6)",
        complete: (anim) => {
          this.refreshAndShuffle();
        },
      });
    },
    playRandomVideo() {
      const videosOnly = this.gallery.filter((item) => item.type === "video");
      this.playVideo(videosOnly[0]);
    },
    async refreshAndShuffle() {
      try {
        await axios.get("/display/refresh").then((response) => {
          this.populateGallery(response.data);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.populateGallery(this.videos);

    this.$refs.plyr.player.on("ended", () => {
      this.animateClosed();
    });
  },
});
</script>
