<template>
  <div class="relative aspect-w-3 aspect-h-4">
    <div
      class="overflow-hidden bg-brand-grey shadow-lg rounded-xl"
      :class="panelStyles"
      @click="handleClick"
    >
      <img :src="item.thumb_url" v-if="item.type === 'video'" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["play"],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick() {
      if (this.item.type === "video") {
        this.$emit("play", this.item);
      }
    },
  },
  computed: {
    panelStyles() {
      return this.item.type === "video"
        ? "cursor-pointer hover:scale-105 transition-transform"
        : "opacity-70";
    },
  },
};
</script>

<style></style>
