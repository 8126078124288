<template>
  <div
    class="text-white p-4 md:p-8 absolute top-0 left-0 z-10 flex items-center space-x-3"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 md:h-8 md:w-8 animate-spin"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
        clip-rule="evenodd"
      />
    </svg>
    <div class="text-sm md:text-lg font-bold">Loading Camera</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
