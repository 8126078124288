<template>
  <Head title="Submit Your Video" />
  <div
    class="min-h-screen text-white bg-fixed bg-center bg-cover min-w-screen bg-pattern bg-brand-orange"
  >
    <div class="p-4 pb-24 md:p-6 lg:p-12">
      <div class="container mx-auto max-w-7xl">
        <div class="grid grid-cols-12">
          <div class="col-span-12">
            <div class="grid grid-cols-12 items-center md:pb-12">
              <div class="col-span-2 md:col-span-4">
                <button
                  @click="handleBack"
                  class="flex items-center justify-center w-12 h-12 rounded-full"
                >
                  <FontAwesome
                    :icon="['fas', 'arrow-left']"
                    class="text-2xl text-brand-green lg:text-3xl xl:text-4xl"
                  />
                </button>
              </div>
              <div
                class="col-span-10 md:col-span-8 flex justify-left md:justify-end"
              ></div>
            </div>
          </div>
          <div
            ref="panelQuestions"
            class="col-span-12 space-y-8 lg:flex lg:justify-between lg:col-span-5"
            v-show="step === 1"
          >
            <div class="space-y-4 lg:space-y-8">
              <div
                class="px-6 py-8 -ml-4 bg-brand-blue-500 lg:py-12 lg:px-12 lg:-ml-12"
              >
                <FormRow label="" size="xl">
                  <div
                    class="py-8 space-y-6 text-brand-green lg:w-11/12 lg:space-y-8 bg-brand-orange"
                  >
                    <label
                      class="flex text-3xl font-bold cursor-pointer lg:text-4xl"
                    >
                      <input
                        type="radio"
                        name="question"
                        value="a"
                        class="w-6 h-6 mt-2 text-brand-green focus:ring-brand-red hidden"
                        v-model="form.question"
                      />
                      <span :class="{ 'opacity-50': !isQuestionSelected('a') }">
                        Share your words of encouragement to cheer on the Sydney
                        Swans for the 2024 AFL finals.</span
                      >
                    </label>
                    <div class="text-2xl w-3/4 font-semibold pt-2">
                      Share your answer in 20 seconds or less
                    </div>
                  </div>
                </FormRow>
              </div>
              <IconButton
                title="Record a response"
                :icon="['far', 'video']"
                class="w-full md:w-auto lg:hidden"
                @click.native="toggleMobileFormSections"
              />
            </div>
          </div>
          <div
            ref="panelWebcam"
            class="relative hidden col-span-12 overflow-hidden shadow-2xl lg:inline-block lg:col-span-6 lg:col-start-7 rounded-2xl border-2 border-black"
            v-show="step === 1"
          >
            <Webcam
              ref="webcam"
              @error="notify"
              @finished="notifyWebcamFinished"
            />
            <div
              class="absolute inset-0 cursor-pointer"
              @click="notifyStandardMessageType('questionRequired')"
              v-if="!form.question"
            />
          </div>
          <div
            v-show="step === 2"
            class="relative flex flex-col justify-center col-span-12 space-y-4 text-brand-green lg:col-span-6 lg:col-start-4 lg:space-y-8"
            :class="{ 'pointer-events-none cursor-wait': form.processing }"
          >
            <div
              class="px-2 md:px-8 py-8 space-y-6 bg-brand-orange lg:px-16 lg:py-16 lg:space-y-8"
            >
              <h2 class="text-lg md:text-2xl font-bold text-center lg:text-3xl">
                Please enter your details below
              </h2>
              <FormRow label="Name">
                <FieldInput
                  placeholder="Your name"
                  v-model="form.name"
                  :error="form.errors.name"
                />
              </FormRow>
              <FormRow label="Email">
                <FieldInput
                  type="email"
                  placeholder="Your email address"
                  class="w-full"
                  v-model="form.email"
                  :error="form.errors.email"
                />
              </FormRow>
              <FormRow label="Phone">
                <FieldInput
                  type="tel"
                  placeholder="Your phone number"
                  v-model="form.phone"
                  :error="form.errors.phone"
                />
              </FormRow>
              <FormRow label="Postcode">
                <FieldInput
                  placeholder="Your postcode"
                  v-model="form.postcode"
                  :error="form.errors.postcode"
                />
              </FormRow>
              <FormRow>
                <FieldCheckbox
                  class="block mt-3"
                  v-model:checked="form.terms"
                  :error="form.errors.terms"
                >
                  <div class="text-lg font-semibold space-y-2">
                    I have read and agree to the
                    <a
                      href="https://docs.google.com/document/u/2/d/e/2PACX-1vRhNDKrpfXxpZJiwB9zv41Fsdoqeif4xMWthatC11Eh8RC_GhzCN6_GVWjKg3JcstoD91LLu2HOQU1l/pub"
                      target="_blank"
                      class="underline"
                      >Privacy Policy</a
                    >, including having my video used for Origin and Sydney
                    Swans marketing purposes.
                  </div>
                </FieldCheckbox>
              </FormRow>
              <FormRow>
                <FieldCheckbox
                  v-model:checked="form.marketing"
                  :error="form.errors.marketing"
                >
                  <div class="text-lg font-semibold space-y-2">
                    I agree to signing up to Origin marketing emails
                  </div>
                </FieldCheckbox>
              </FormRow>
            </div>

            <FormRow>
              <div class="flex justify-center">
                <IconButton
                  class="w-full lg:w-auto"
                  title="Submit"
                  v-if="!form.progress"
                  :disabled="form.processing"
                  @click.native="uploadAndSubmit"
                />
                <IconButton
                  class="w-full lg:w-auto"
                  title="Uploading your video"
                  :icon="['fas', 'loader']"
                  :disabled="form.processing"
                  icon-spin
                  v-else
                />
              </div>
            </FormRow>
          </div>
        </div>
      </div>
      <TermsLink class="mt-12 lg:mt-0" />
    </div>
    <!-- <ModalConfirmation
      ref="modalConfirmation"
      @callback="confirmationCallbackHandler"
    /> -->
    <ModalNotification
      ref="modalNotifications"
      :id="notification.id"
      :title="notification.title"
      :button="notification.button"
      @callback="notificationCallbackHandler"
    >
      {{ notification.message }}
    </ModalNotification>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Head, useForm } from "@inertiajs/inertia-vue3";
import getBlobDuration from "get-blob-duration";
import language from "@/data.json";
import FormRow from "@/Components/form/FormRow";
import FieldInput from "@/Components/form/FieldInput";
import FieldSelect from "@/Components/form/FieldSelect";
import FieldCheckbox from "@/Components/form/FieldCheckbox";
import FieldRadio from "@/Components/form/FieldRadio";
import IconButton from "@/Components/IconButton";
import TermsLink from "@/Components/TermsLink";
import Webcam from "@/Components/capture/Webcam";
import ModalNotification from "@/Components/modals/ModalNotification";
import ModalConfirmation from "@/Components/modals/ModalConfirmation";

export default defineComponent({
  components: {
    Head,
    Webcam,
    IconButton,
    FormRow,
    FieldInput,
    FieldSelect,
    FieldCheckbox,
    FieldRadio,
    TermsLink,
    ModalNotification,
    ModalConfirmation,
  },
  props: {
    videoId: String,
    errors: Object,
  },
  data() {
    return {
      step: 1,
      language,
      notification: {
        id: "",
        title: "",
        message: "",
        button: "",
      },
      video: null,
      form: useForm({
        question: "a",
        name: "",
        email: "",
        phone: "",
        postcode: "",
        terms: false,
        marketing: false,
        file: null,
        mime: null,
        duration: 0,
      }),
    };
  },
  methods: {
    isQuestionSelected(answer) {
      if (!this.form.question) {
        return true;
      }
      return this.form.question && this.form.question === answer;
    },
    toggleMobileFormSections() {
      if (!this.form.question) {
        this.notifyStandardMessageType("questionRequired");
        return;
      }

      if (this.$refs.panelQuestions.classList.contains("hidden")) {
        this.$refs.panelQuestions.classList.remove("hidden");
        this.$refs.panelWebcam.classList.add("hidden");
      } else {
        this.$refs.panelQuestions.classList.add("hidden");
        this.$refs.panelWebcam.classList.remove("hidden");
      }
    },
    notifyStandardMessageType(language) {
      this.notification = this.language.notifications[language];
      this.$refs.modalNotifications.openModal();
    },
    notify(notification) {
      this.notification = {
        ...notification,
        button: notification.button || "OK",
      };
      this.notification.button = this.$refs.modalNotifications.openModal();
    },
    async notifyWebcamFinished(video) {
      try {
        this.form.file = video.blob;
        this.form.mime = video.mime;
        // Fix meta issues and get duration for dynamic video
        const duration = await getBlobDuration(this.form.file);

        // Set duration in milliseconds
        this.form.duration = duration * 1000;

        // Pause the preview video
        this.$refs.webcam.pauseVideo();

        // Show next step
        this.goToStep(2);
      } catch (error) {
        console.error(error);
      }
    },
    handleBack() {
      this.goToStep(this.step - 1);
    },
    goToStep(step) {
      // If already on step 1 go home
      if (step === 0) {
        this.$inertia.visit("/?welcome=false");
        return;
      }
      // Check question selected
      if (step === 1 && !this.form.question) {
        this.notifyStandardMessageType("questionRequired");
        return;
      }
      // Check video recorded
      if (step === 2 && !this.form.file) {
        return;
      }

      this.step = step;
    },
    displayConfirmation() {
      this.$refs.modalConfirmation.openModal();
    },
    async uploadAndSubmit() {
      try {
        // Submit video
        this.form.post("/upload", {
          preserveScroll: true,
          onSuccess: () => {
            // Log action in google analytics
            if (process.env.MIX_APP_ENV === "production") {
              gtag("event", "sign_up", {
                event_category: "engagement",
                event_label: "method",
              });
            }

            // Clear the form
            this.form.reset();

            // Notify success
            this.notifyStandardMessageType("videoUploaded");
          },
        });
      } catch (error) {
        console.error(error);
        this.notify({
          id: "videoUploadError",
          title: "Error",
          message: error,
          button: "OK",
        });
      }
    },
    confirmationCallbackHandler(callback) {
      if (callback.action === "confirm") {
        this.uploadAndSubmit();
      }
    },
    notificationCallbackHandler(callback) {
      if (callback.id === "videoUploaded") {
        this.$inertia.visit(`/video/${this.videoId}`);
      }
    },
  },
});
</script>
