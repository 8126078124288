<template>
  <label
    class="flex items-start space-x-6 text-sm font-bold cursor-pointer lg:text-lg"
  >
    <input
      type="checkbox"
      name="question"
      :checked="checked"
      class="appearance-none w-6 h-6 mt-1 rounded cursor-pointer focus:ring-brand-yellow checked:text-brand-orange checked:bg-brand-yellow hover:checked:bg-brand-yellow"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <div class="flex">
      <span>
        <slot />
        <div
          v-if="error"
          class="inline-block rounded-full px-3 text-xs font-semibold text-white bg-red-700 lg:text-base py-0.5 mt-2"
        >
          {{ error }}
        </div>
      </span>
    </div>
  </label>
</template>

<script>
export default {
  emit: ["update:modelValue"],
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
