<template>
  <app-layout title="Dashboard">
    <template #header>
      <h2 class="text-xl font-semibold leading-tight text-gray-800 font-admin">
        Video Moderation
      </h2>
      <a
        href="/dashboard/export"
        class="border h-8 rounded px-3 shadow-sm flex items-center font-medium text-sm font-admin"
      >
        Download Data Capture
      </a>
    </template>

    <div class="py-12 font-admin">
      <div class="mx-auto max-w-8xl sm:px-6 lg:px-8">
        <div class="bg-white shadow sm:rounded-lg">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              >
                <div class="border-b border-gray-200 shadow sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Video
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Postcode
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Marketing
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Encoding
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Moderation
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Date
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="video in videos" :key="video.uuid">
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <img
                                class="inline-block w-20 bg-cover rounded cursor-pointer"
                                :src="video.thumb_url"
                                @click="playVideo(video)"
                              />
                            </div>
                          </div>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div
                            class="text-base font-bold text-gray-900 underline"
                          >
                            <a
                              :href="`mailto:${video.email}`"
                              target="_blank"
                              >{{ video.name }}</a
                            >
                          </div>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div class="text-base text-gray-900">
                            {{ video.phone }}
                          </div>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div class="text-base text-gray-900">
                            {{ video.postcode }}
                          </div>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div class="text-base text-gray-900">
                            <span v-if="video.marketing">Yes</span>
                            <span v-else>No</span>
                          </div>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <span
                            class="inline-flex h-5 px-2 text-xs font-bold leading-5 text-orange-600 bg-orange-200 rounded-full uppercase"
                            v-if="video.status === 'PROCESSING'"
                          >
                            Processing
                          </span>
                          <span
                            class="inline-flex h-5 px-2 text-xs font-bold leading-5 text-green-600 bg-green-200 rounded-full uppercase"
                            v-else
                          >
                            Finished
                          </span>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <span
                            class="inline-flex h-5 px-2 text-xs font-bold leading-5 text-orange-600 bg-orange-200 rounded-full uppercase"
                            v-if="video.flagged === 0"
                          >
                            Unchecked
                          </span>
                          <span
                            class="inline-flex h-5 px-2 text-xs font-bold leading-5 text-red-600 bg-red-200 rounded-full uppercase"
                            v-if="video.flagged === 2"
                          >
                            Rejected
                          </span>
                          <span
                            class="inline-flex h-5 px-2 text-xs font-bold leading-5 text-green-600 bg-green-200 rounded-full uppercase"
                            v-if="video.flagged === 1"
                          >
                            Approved
                          </span>
                        </td>
                        <td class="px-5 py-3 whitespace-nowrap">
                          <div class="text-base text-gray-900">
                            {{ formatDate(video.created_at) }}
                          </div>
                        </td>
                        <td
                          class="px-5 py-3 text-base font-medium text-right whitespace-nowrap"
                        >
                          <div class="relative inline-block text-left">
                            <Menu>
                              <MenuButton>
                                <button
                                  type="button"
                                  class="relative inline-flex justify-center w-full px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 z-0"
                                  id="menu-button"
                                  aria-expanded="true"
                                  aria-haspopup="true"
                                >
                                  Options
                                  <!-- Heroicon name: solid/chevron-down -->
                                  <svg
                                    class="w-5 h-5 ml-2 -mr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </MenuButton>
                              <MenuItems>
                                <div
                                  class="absolute right-0 z-50 w-42 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  role="menu"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button"
                                  tabindex="-1"
                                >
                                  <div class="py-1 z-50" role="none">
                                    <MenuItem v-slot="{ active }">
                                      <button
                                        :class="{ 'bg-gray-200': active }"
                                        class="items-center block w-full px-4 py-2 space-x-2 text-xs text-gray-900 text-left font-semibold"
                                        role="menuitem"
                                        tabindex="-1"
                                        id="menu-item-0"
                                        @click="moderate(video.uuid, 'accept')"
                                      >
                                        <span>Moderation: Approve</span>
                                      </button>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                      <button
                                        :class="{ 'bg-gray-200': active }"
                                        class="items-center block w-full px-4 py-2 space-x-2 text-xs text-gray-900 text-left font-semibold"
                                        role="menuitem"
                                        tabindex="-1"
                                        id="menu-item-1"
                                        @click="moderate(video.uuid, 'reject')"
                                      >
                                        <span>Moderation: Reject</span>
                                      </button>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        :href="video.video_url_web"
                                        target="_blank"
                                        :class="{ 'bg-gray-200': active }"
                                        class="items-center block w-full px-4 py-2 space-x-2 text-xs text-gray-900 text-left border-t font-semibold"
                                        role="menuitem"
                                        tabindex="-1"
                                        id="menu-item-2"
                                        download
                                      >
                                        <span>Download Video</span>
                                      </a>
                                    </MenuItem>
                                  </div>
                                </div>
                              </MenuItems>
                            </Menu>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalVideoPlayer
      ref="modalVideoPlayer"
      :video="modalVideo"
      background-colour="bg-white"
    />
  </app-layout>
</template>

<script>
import { defineComponent } from "vue";
import { Inertia } from "@inertiajs/inertia";
import dayjs from "dayjs";
import AppLayout from "@/Layouts/AppLayout.vue";
import Welcome from "@/Jetstream/Welcome.vue";
import ModalVideoPlayer from "@/Components/modals/ModalVideoPlayer";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default defineComponent({
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppLayout,
    Welcome,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ModalVideoPlayer,
  },
  data() {
    return {
      modalVideo: "",
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY [at] H:mm");
    },
    playVideo(video) {
      this.modalVideo = video;
      this.$refs.modalVideoPlayer.openModal();
    },
    moderate(id, status) {
      try {
        Inertia.visit(`/video/${id}/moderate`, {
          method: "post",
          preserveScroll: true,
          data: {
            status,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
</script>
