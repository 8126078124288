import "./bootstrap";
import { Vue3Mq } from "vue3-mq";
import VuePlyr from "vue-plyr";
import FontAwesomeIcon from "./icons";
import { createApp, h } from "vue";
import { InertiaProgress } from "@inertiajs/progress";
import { createInertiaApp } from "@inertiajs/inertia-vue3";

import "vue-plyr/dist/vue-plyr.css";

InertiaProgress.init({
  color: "#F26331",
});

createInertiaApp({
  resolve: (name) => require(`./Pages/${name}`),
  title: (title) =>
    title
      ? `${title} - Cheer on the Swans with Origin`
      : "Cheer on the Swans with Origin",
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .mixin({ methods: { route } })
      .component("FontAwesome", FontAwesomeIcon)
      .use(VuePlyr, {
        plyr: {},
      })
      .use(plugin)
      .use(Vue3Mq, {
        preset: "tailwind",
      })
      .mount(el);
  },
});
