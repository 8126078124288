<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-lg p-3 space-y-4 overflow-hidden text-left align-middle transition-all transform shadow-xl md:space-y-8 md:p-4 rounded-2xl"
              :class="backgroundColour"
            >
              <div class="overflow-hidden rounded-lg">
                <vue-plyr :options="options">
                  <video
                    controls
                    crossorigin
                    playsinline
                    :data-poster="video.poster_url"
                  >
                    <source
                      size="1080"
                      :src="video.video_url_web"
                      type="video/mp4"
                    />
                    <track
                      default
                      kind="captions"
                      label="English"
                      :src="video.subtitles_url"
                      srclang="en"
                      v-if="video.subtitles_url"
                    />
                  </video>
                </vue-plyr>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
} from "@headlessui/vue";
import IconButton from "@/Components/IconButton";

export default {
  emits: ["callback", "finished"],
  props: {
    video: {
      type: [Object, Boolean],
      required: true,
    },
    autoplay: {
      type: Boolean,
      required: false,
    },
    backgroundColour: {
      type: String,
      default: "bg-white",
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    IconButton,
  },
  data() {
    return {
      options: {
        ratio: "3:4",
      },
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("callback", {
        action: "close",
      });
    },
  },
};
</script>
