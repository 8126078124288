<template>
  <button
    :height="size.height"
    :width="size.width"
    class="relative flex items-center justify-center"
  >
    <svg
      :height="size.height"
      :width="size.width"
      :viewBox="svgViewBox"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        stroke="inherit"
        :stroke-width="size.stroke"
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        :height="radius * 2"
        :width="radius * 2"
        class="stroke-white/30"
      />
      <circle
        stroke="inherit"
        class="transition-all"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset }"
        :stroke-width="size.stroke"
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        :height="size.height"
        :width="size.width"
        :class="strokeColourClass"
      />
      <circle
        :cx="radius"
        :cy="radius"
        :r="buttonRadius"
        class="transition-transform duration-75 fill-red-600 hover:scale-105"
      />
    </svg>
    <div
      class="absolute inset-0 z-20 flex items-center justify-center transition-all"
      :class="{ 'opacity-100': icon.active, 'opacity-50': !icon.active }"
    >
      <FontAwesome
        :icon="['fas', icon.type]"
        :spin="icon.spin"
        v-if="icon.type"
        class="text-4xl"
      />
    </div>
  </button>
</template>

<script>
export default {
  inject: ["mq"],
  props: {
    duration: {
      type: Number,
      default: 90,
    },
    icon: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    size() {
      const sizes = {
        xs: {
          width: "90",
          height: "90",
          stroke: 5,
        },
        sm: {
          width: "100",
          height: "100",
          stroke: 6,
        },
        md: {
          width: "110",
          height: "110",
          stroke: 6,
        },
        default: {
          width: "120",
          height: "120",
          stroke: 7,
        },
      };
      return sizes[this.mq.current] || sizes.default;
    },
    svgViewBox() {
      return `0 0 ${this.size.width} ${this.size.height}`;
    },
    radius() {
      return this.size.width / 2;
    },
    buttonRadius() {
      return this.size.width / 2.7;
    },
    normalizedRadius() {
      return this.radius - this.size.stroke;
    },
    strokeDashoffset() {
      this.progress = (this.duration / this.config.recordLength.max) * 100;
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeColourClass() {
      return this.duration >= this.config.recordLength.min
        ? "stroke-green-600"
        : "stroke-white/60";
    },
  },
};
</script>

<style lang="css">
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
