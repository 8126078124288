<template>
  <button
    class="absolute top-0 right-0 z-30 p-4 text-white md:p-8 text-shadow"
    @click="toggleConfig"
  >
    <FontAwesome :icon="['fas', 'repeat']" class="w-6 h-6 md:h-8 md:w-8" />
  </button>
</template>

<script>
export default {};
</script>
