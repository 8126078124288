<template>
  <component
    :is="element"
    class="inline-flex items-center justify-center font-semibold text-white transition-colors border-white bg-brand-green ring-offset-4 whitespace-nowrap font-sans border-2 rounded-full bg-brand-orange"
    :class="sizeClasses"
    :disabled="disabled"
  >
    <FontAwesome :icon="icon" v-if="icon" :spin="iconSpin" />
    <span>{{ title }}</span>
  </component>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "large",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [Array, Boolean],
      default: false,
    },
    iconSpin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClasses() {
      const sizes = {
        small: "h-10 px-4 space-x-3 lg:px-6 lg:text-lg lg:space-x-4",
        large:
          "h-12 lg:h-12 lg:py-6 px-6 space-x-3 text-lg lg:px-6 lg:text-xl lg:space-x-4",
      };
      return sizes[this.size];
    },
  },
};
</script>
