<template>
  <Head title="Gallery" />
  <div
    class="min-h-screen text-white bg-fixed bg-cover min-w-screen bg-pattern bg-brand-orange"
  >
    <div class="p-6 pb-0 lg:p-12">
      <div class="container mx-auto max-w-screen-2xl">
        <div class="grid grid-cols-12 lg:gap-x-20">
          <div
            class="flex-col col-span-12 space-y-8 lg:flex lg:justify-between lg:col-span-5"
          >
            <div
              class="sticky space-y-2 top-6 lg:top-12 lg:space-y-8 bg-brand-orange"
            >
              <div
                class="px-6 py-10 -ml-6 space-y-6 text-white lg:px-12 lg:-ml-12"
              >
                <h1 class="text-5xl font-bold leading-12">
                  Cheer cheer the red and white!
                </h1>
                <div class="space-y-4 font-semibold xl:text-lg">
                  <p>
                    As proud supporters of the Sydney Swans, Origin is excited
                    to cheer them on during their finals journey!
                  </p>
                  <p>
                    And we would love to hear your support too! Record a short
                    video with a message of encouragement to boost the Swans'
                    motivation. Together with Origin, let’s cheer the Swans to
                    victory!
                  </p>
                </div>
              </div>
              <div>
                <Link href="/submit">
                  <IconButton
                    title="Record a video"
                    :icon="['far', 'video']"
                    class="w-full md:w-auto"
                  />
                </Link>
              </div>
              <div class="bg-brand-orange pt-12">
                <img
                  src="/images/logogrid.svg"
                  alt="Origin & Sydney Swans logo"
                  class="h-24 lg:h-32"
                />
              </div>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-7">
            <TransitionGroup
              name="list"
              tag="div"
              class="grid grid-cols-2 gap-4 py-12 md:grid-cols-5 lg:py-0 lg:gap-3 xl:gap-4"
            >
              <GalleryThumbnail
                v-for="item in gallery"
                :item="item"
                :key="item.uuid"
                @play="playVideo"
              />
            </TransitionGroup>
            <TermsLink />
          </div>
        </div>
      </div>
    </div>
    <ModalWelcome ref="modalWelcome" />
    <ModalVideoPlayer ref="modalVideoPlayer" :video="modalVideo" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import { v4 as uuidv4 } from "uuid";
import GalleryThumbnail from "@/Components/GalleryThumbnail";
import IconButton from "@/Components/IconButton";
import TermsLink from "@/Components/TermsLink";
import ModalWelcome from "@/Components/modals/ModalWelcome";
import ModalVideoPlayer from "@/Components/modals/ModalVideoPlayer";

export default defineComponent({
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  components: {
    Head,
    Link,
    IconButton,
    TermsLink,
    GalleryThumbnail,
    ModalWelcome,
    ModalVideoPlayer,
  },
  data() {
    return {
      gallery: [],
      modalVideo: false,
    };
  },
  methods: {
    // Conditionally open the modal
    openModal() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (!urlParams.get("welcome")) {
        this.$refs.modalWelcome.openModal();
      }
    },
    // Generate padded gallery
    populateGallery() {
      const items = this.videos;
      const target = 30;
      const shortfall = target - items.length;

      if (shortfall) {
        for (let i = 0; i < shortfall; i++) {
          items.push({
            uuid: uuidv4(),
            type: "placeholder",
          });
        }
        this.gallery = items;
      } else {
        this.gallery = items;
      }
    },
    // Add to gallery if the video is new
    addToGallery(video) {
      // TODO: Add video updating for existing videos
      // TODO: Remove placeholders
      if (video.status === "READY_PRIVATE" || video.status === "READY_PUBLIC") {
        if (!this.gallery.find((item) => item.uuid === video.uuid)) {
          this.gallery.unshift(video);
          // Remove placeholder final placeholder if there is one
          const lastItem = this.gallery[this.gallery.length - 1];
          if (lastItem.type === "placeholder") {
            this.gallery.pop();
          }
        }
      }
    },
    playVideo(video) {
      this.modalVideo = video;
      this.$refs.modalVideoPlayer.openModal();
    },
  },
  mounted() {
    // Listen for media updates
    window.Echo.channel("videos").listen(".VideoUpdated", (video) => {
      if (video.flagged === 1) {
        this.addToGallery(video);
      }
    });

    this.populateGallery();
    this.openModal();
  },
});
</script>
