<template>
  <Head title="Your Video" />
  <div class="min-h-screen text-white min-w-screen bg-pattern bg-brand-orange">
    <div class="p-6 py-12 lg:p-12">
      <div class="container max-w-3xl mx-auto space-y-10 md:space-y-12">
        <div
          class="flex flex-col justify-center space-y-4 text-center bg-brand-orange"
        >
          <h1
            class="text-3xl font-bold xl:text-5xl text-brand-green leading-10 md:pt-12"
          >
            Help Origin cheer on the Sydney Swans
          </h1>
          <h2 class="text-xl md:text-3xl">
            Share your support with your followers!
          </h2>
        </div>
        <div
          class="overflow-hidden rounded-xl border-2 border-white bg-brand-orange"
        >
          <div
            v-if="!videoReady"
            class="text-brand-green aspect-w-3 aspect-h-4 bg-brand-blue-400 rounded-xl"
          >
            <div class="flex justify-center items-center">
              <div
                class="flex flex-col justify-center w-9/12 lg:w-10/12 space-y-4 lg:space-y-8 text-center"
              >
                <FontAwesome
                  :icon="['fas', 'loader']"
                  class="text-3xl md:text-5xl"
                  spin
                />
                <div class="space-y-2 lg:space-y-4">
                  <p class="font-bold text-lg lg:text-3xl">
                    Your video will show here when ready
                  </p>
                  <p class="text-sm lg:text-lg lg:px-12">
                    Video processing takes around <strong>30 seconds</strong>.
                    We'll also send you a link via email in case you'd prefer
                    not to wait.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <vue-plyr :options="options" v-else>
            <video
              controls
              crossorigin
              playsinline
              :data-poster="video.poster_url"
            >
              <source size="1080" :src="video.video_url_web" />
              <track
                default
                kind="captions"
                label="English"
                :src="video.subtitles_url"
                srclang="en"
                v-if="video.subtitles_url"
              />
            </video>
          </vue-plyr>
        </div>
        <div class="flex justify-center">
          <div
            class="grid grid-cols-4 gap-4 md:gap-12"
            :class="{ 'opacity-50 cursor-not-allowed': !videoReady }"
          >
            <div
              class="flex items-center justify-center w-12 h-12 transition-transform border-2 border-white rounded-full cursor-pointer md:w-16 md:h-16 hover:scale-105 cursor"
              :class="{ 'pointer-events-none': !videoReady }"
              @click="shareFacebook"
            >
              <FontAwesome
                :icon="['fab', 'facebook-f']"
                class="text-xl md:text-3xl"
              />
            </div>
            <div
              class="flex items-center justify-center w-12 h-12 transition-transform border-2 border-white rounded-full cursor-pointer md:w-16 md:h-16 hover:scale-105"
              :class="{ 'pointer-events-none': !videoReady }"
              @click="shareTwitter"
            >
              <FontAwesome
                :icon="['fab', 'twitter']"
                class="text-xl md:text-3xl"
              />
            </div>
            <div
              class="flex items-center justify-center w-12 h-12 transition-transform border-2 border-white rounded-full cursor-pointer md:w-16 md:h-16 hover:scale-105"
              :class="{ 'pointer-events-none': !videoReady }"
              @click="$refs.modalShareSocial.openModal('instagram')"
            >
              <FontAwesome
                :icon="['fab', 'instagram']"
                class="text-xl md:text-3xl"
              />
            </div>
            <div
              class="flex items-center justify-center w-12 h-12 transition-transform border-2 border-white rounded-full cursor-pointer md:w-16 md:h-16 hover:scale-105"
              :class="{ 'pointer-events-none': !videoReady }"
              @click="$refs.modalShareSocial.openModal('tiktok')"
            >
              <FontAwesome
                :icon="['fab', 'tiktok']"
                class="text-xl md:text-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalShareSocial ref="modalShareSocial" :video="video" />
  </div>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import { defineComponent } from "vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import IconButton from "@/Components/IconButton";
import TermsLink from "@/Components/TermsLink";
import ModalShareSocial from "@/Components/modals/ModalShareSocial";
export default defineComponent({
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  components: {
    Head,
    Link,
    IconButton,
    TermsLink,
    ModalShareSocial,
  },
  data() {
    return {
      options: {
        ratio: "3:4",
      },
    };
  },
  computed: {
    videoReady() {
      return this.video.video_url_web && this.video.poster_url ? true : false;
    },
  },
  methods: {
    notificationCallbackHandler(callback) {
      if (callback.id === "videoUploaded") {
        this.$inertia.visit("/?welcome=false");
      }
    },
    shareFacebook() {
      window
        .open(
          `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
          "_blank"
        )
        .focus();
    },
    shareTwitter() {
      window
        .open(
          `https://twitter.com/intent/tweet?url=${window.location.href}`,
          "_blank"
        )
        .focus();
    },
  },
  mounted() {
    // Listen for media updates
    window.Echo.channel("videos").listen(".VideoUpdated", (video) => {
      if (video.id === this.video.id && !this.videoReady) {
        Inertia.reload({ only: ["video"] });
      }
    });
  },
});
</script>
