<template>
  <button
    class="relative flex items-center h-8 px-3 space-x-2 text-sm font-semibold text-white transition-all border-2 border-white rounded-full md:space-x-3 md:px-4 md:h-10 md:text-lg lg:text-xl hover:border-gray-500 bg-black/50"
  >
    <span>{{ title }}</span>
    <FontAwesome
      :icon="['fas', icon]"
      class="text-xs md:text-sm lg:text-lg"
      v-if="icon"
    />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
