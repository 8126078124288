<template>
  <div
    class="bottom-8 left-0 w-full py-3 text-center lg:fixed lg:text-left lg:px-12 lg:py-3 lg:text-lg space-x-2 font-semibold"
  >
    <!-- <a href="#" target="_blank" class="text-brand-green hover:underline">
      Terms & Conditions
    </a> -->
    <!-- <span class="opacity-50">|</span> -->
    <a
      href="https://docs.google.com/document/u/2/d/e/2PACX-1vRhNDKrpfXxpZJiwB9zv41Fsdoqeif4xMWthatC11Eh8RC_GhzCN6_GVWjKg3JcstoD91LLu2HOQU1l/pub"
      target="_blank"
      class="text-brand-green hover:underline"
    >
      Privacy Policy
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
