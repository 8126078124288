<template>
  <div class="flex flex-col space-y-2 lg:space-y-4">
    <label
      v-if="label"
      class="font-semibold text-brand-green"
      :class="sizeClasses"
      >{{ label }}</label
    >
    <div class="flex flex-col space-y-4 lg:space-y-6">
      <slot />
    </div>
    <div v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    size: {
      required: false,
      type: String,
      default: "default",
    },
  },
  computed: {
    sizeClasses() {
      const sizes = {
        default: "text-lg md:text-xl",
        lg: "md:text-2xl lg:text-3xl",
        xl: "text-2xl md:text-2xl lg:text-3xl",
      };
      return sizes[this.size];
    },
  },
};
</script>

<style></style>
