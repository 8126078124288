<template>
  <div class="container w-2/3 mx-auto space-y-8 md:max-w-lg">
    <Multicorder
      ref="multicorder"
      :video-source="videoSource"
      :videoTypes="videoTypes"
      :recorderMode="recorderMode"
      @error="onError"
      @cameras="onCameras"
      @video-live="onVideoLive"
      @view-change="onViewChange"
      @new-recording="onNewRecording"
      @delete-recording="onDeleteRecording"
      @player-loaded="onPlayerLoaded"
      @uploading="onUploading"
    />
    <select @change="onInputSelected">
      <option
        :value="camera.value"
        v-for="(camera, index) in videoSourceList"
        :key="index"
      >
        {{ camera.text }}
      </option>
    </select>
    <div v-if="view == 'videoPlayer'" class="flex justify-center space-x-2">
      <button
        @click="playRecording"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Play
      </button>
      <button
        @click="deleteRecording"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Delete
      </button>
      <button
        @click="uploadVideo"
        class="px-2 py-1 text-black border border-black rounded"
      >
        <span v-if="!uploading">Upload</span>
        <span v-else>Uploading...</span>
      </button>
    </div>
    <div
      v-if="view == 'video' && videoSource != null"
      class="flex justify-center space-x-2"
    >
      <button
        v-if="controls == 'liveVideo'"
        @click="videoRecord"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Record
      </button>
      <button
        v-if="controls == 'recordingVideo'"
        @click="videoStopRecording"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Stop
      </button>
      <button
        @click="pause"
        v-if="!isPaused && controls == 'recordingVideo'"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Pause
      </button>
      <button
        @click="resume"
        v-if="isPaused && controls == 'recordingVideo'"
        class="px-2 py-1 text-black border border-black rounded"
      >
        Paused
      </button>
    </div>
  </div>
</template>

<script>
import Multicorder from "@/Components/WebcamCapture";

export default {
  components: {
    Multicorder,
  },
  props: {
    videoTypes: {
      type: Array,
      default: () => {
        return ["camera", "screen"];
      },
    },
    recorderMode: {
      type: String,
      default: "single",
    },
  },
  data() {
    return {
      uploading: false,
      controls: null,
      videoSource: null,
      videoSourceList: [],
      isPaused: false,
      isPlayerPaused: false,
      isMuted: true,
      isPlayerMuted: true,
      view: "video",
      recordings: [], // local sparsed list of recording data
    };
  },
  methods: {
    onError(error) {
      console.log("Error emitted", error);
    },
    onCameras(cameras) {
      console.log("Available cameras", cameras);
      /**
       * We are implementing a `multicorder` with camera and screen support.
       * We need to create a list that groups the items for a `v-select` component.
       * We use the `listFromCameras` helper function provided by the component.
       * The Multicorder component maintains a list of `cameras` if we need them independently.
       */
      this.videoSourceList = this.$refs.multicorder.listFromCameras(cameras);
      this.videoSource = this.videoSourceList[0];
    },
    onVideoLive() {
      this.controls = "liveVideo";
    },
    onViewChange(view) {
      this.view = view;
    },
    onNewRecording(recording) {
      this.recordings.push(recording);
      if (this.recorderMode == "single") {
        // Load the video into the player and force disposition
        // this.view = "videoPlayer";
        this.loadRecording(0);
      }
    },
    onDeleteRecording(index) {
      this.recordings.splice(index, 1);
      if (this.recorderMode == "single") {
        this.controls = "liveVideo";
      }
    },
    onPlayerLoaded() {
      //this.playRecording();
    },
    onInputSelected(event) {
      const selected = this.videoSourceList.filter(
        (camera) => camera.value == event.target.value
      );
      this.videoSource = selected[0];
      this.$refs.multicorder.changeVideoSource(selected[0]);
    },
    onUploading(uploading) {
      this.uploading = uploading;
    },
    videoRecord() {
      this.controls = "recordingVideo";
      this.$refs.multicorder.startVideoRecording();
    },
    videoSnapshot(fromView) {
      this.$refs.multicorder.videoSnapshot(fromView);
    },
    videoClose() {
      this.$refs.multicorder.stopVideo();
      this.view = "video";
      this.controls = "liveVideo";
      this.videoSource = null;
    },
    videoStopRecording() {
      this.$refs.multicorder.stopRecording();
      // resume the video, minus recording
      this.resume();
    },
    resume() {
      this.isPaused = false;
      this.$refs.multicorder.resume();
    },
    pause() {
      this.isPaused = true;
      this.$refs.multicorder.pause();
    },
    closeSnapshot() {
      this.$refs.multicorder.closeSnapshot();
    },
    snapshotDownload() {
      this.$refs.multicorder.downloadSnapshot();
    },
    downloadRecording(index) {
      if (this.recorderMode === "single") {
        index = 0;
      }
      this.$refs.multicorder.downloadRecording(index);
    },
    deleteRecording(index) {
      if (this.recorderMode === "single") {
        index = 0;
      }
      this.$refs.multicorder.deleteRecording(index);
    },
    async loadRecording(index) {
      await this.$refs.multicorder.loadRecording(index);
    },
    playRecording() {
      this.isPlayerPaused = false;
      this.$refs.multicorder.playRecording();
    },
    pausePlayer() {
      this.isPlayerPaused = true;
      this.$refs.multicorder.pausePlayer();
    },
    resumePlayer() {
      this.isPlayerPaused = false;
      this.$refs.multicorder.resumePlayer();
    },
    deletePlayerRecording() {
      this.$refs.multicorder.deletePlayerRecording();
    },
    closePlayer() {
      this.$refs.multicorder.closePlayer();
    },
    toggleMuted() {
      this.isMuted = !this.isMuted;
    },
    togglePlayerMuted() {
      this.isPlayerMuted = !this.isPlayerMuted;
    },
    uploadVideo() {
      this.$refs.multicorder.uploadVideo();
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
