<template>
  <div class="relative flex flex-col w-full">
    <input
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      class="font-semibold border-white rounded-full bg-transparent placeholder:text-white/50 focus:border-black focus:ring-2 focus:ring-brand-red"
      :class="sizeClasses"
      @input="handleChange"
    />
    <div
      v-if="error"
      class="absolute right-0 px-3 text-xs font-semibold rounded-full text-white -top-3 bg-brand-red lg:text-base bg-red-700 py-0.5"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  emit: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "large",
    },
  },
  computed: {
    sizeClasses() {
      const sizes = {
        small:
          "h-10 px-4 border rounded-full lg:h-12 lg:border-2 lg:px-6 lg:text-xl focus:ring-0",
        large:
          "h-12 px-4 text-lg border-2 rounded-full lg:h-16 lg:px-6 lg:text-2xl focus:ring-0",
      };
      return sizes[this.size];
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style></style>
