<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-lg p-6 space-y-6 overflow-hidden text-left text-white align-middle transition-all transform shadow-xl bg-brand-orange md:space-y-8 md:p-8 rounded-2xl"
            >
              <DialogTitle
                as="h3"
                class="text-3xl font-bold leading-9 text-white text-center"
                >Cheer on the Swans with your words of support</DialogTitle
              >
              <div
                class="space-y-2 text-white md:space-y-3 text-center md:text-lg font-semibold md:px-6"
              >
                <p>
                  All you need to do is record / submit a video of you cheering
                  on the Sydney Swans for this years finals series.
                </p>
                <p>
                  Enter as many times as you like and we’ll share the best ones!
                </p>
                <p>
                  We’ll also send you your video to reshare on socials to really
                  show your support!
                </p>
              </div>

              <div class="text-center">
                <IconButton
                  type="button"
                  ref="confirmButton"
                  title="OK"
                  size="small"
                  @click.native="closeModal"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import IconButton from "@/Components/IconButton";

export default {
  emits: ["callback"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    IconButton,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("callback", {
        action: "close",
      });
    },
  },
};
</script>
